@tailwind base;
@tailwind components;
@tailwind utilities;


/* You can add global styles to this file, and also import other style files */
.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
    color: #5E9925;
  }
  
  .mat-mdc-tab-labels {
    background-color: #F9FAF4;
    user-select: none;
  }
  
  .mat-mdc-tab-body-wrapper {
    height: 100%;
  }